import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { NoReportsComponent } from './components/NoReports';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { Stack, Typography } from '@mui/material';
import {
  HistoricalAssignmentEntry,
  UpdateHistoricalAssignment,
} from '../../../../types/contractor';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { updateUserBonusPlan } from '../../../../redux/userData';
import HistoricalDataTable from './components/HistoricalDataTable';
import { periodsSelectors } from '../../../../redux/periods';
import { PeriodType } from '../../../../types';
import { addMonthsToPeriod, getCurrentQuarter } from '../../Contractor.utils';

interface Props {
  userId: string;
  isOpen: boolean;
  onClose: () => void;
  historicalAssignments: Record<string, HistoricalAssignmentEntry> | undefined;
}

const HistoricalDataEditModal: React.FC<Props> = ({
  userId,
  historicalAssignments,
  isOpen,
  onClose,
}) => {
  const currentPeriod: PeriodType | null = useAppSelector(
    periodsSelectors.getCurrentPeriod,
  );

  const dispatch = useAppDispatch();

  let updateHistoricalDataBody: UpdateHistoricalAssignment[] = [];

  const handleHistoricalDataChange = (ae: UpdateHistoricalAssignment) => {
    if (updateHistoricalDataBody.find((hae) => hae.id === ae.id)) {
      updateHistoricalDataBody = updateHistoricalDataBody.map((hae) =>
        hae.id === ae.id ? ae : hae,
      );
    } else updateHistoricalDataBody.push(ae);
  };

  const onSaveBonuses = () => {
    const cleanupFromCurrValuesBody = updateHistoricalDataBody.map((record) => {
      const newRecord: UpdateHistoricalAssignment = {
        id: record.id,
        monthlyKpiValues: record.monthlyKpiValues,
        quarterlyKpiValues: record.quarterlyKpiValues,
      };

      if (currentPeriod) {
        if (newRecord.monthlyKpiValues) {
          newRecord.monthlyKpiValues = newRecord.monthlyKpiValues.filter(
            (x) => x.period !== currentPeriod.period,
          );
        }

        if (newRecord.quarterlyKpiValues) {
          const currentQuarterResultMonth = addMonthsToPeriod(
            Number(getCurrentQuarter(currentPeriod.period)[1]),
            1,
          );
          newRecord.quarterlyKpiValues = newRecord.quarterlyKpiValues.filter(
            (x) => x.period !== currentQuarterResultMonth,
          );
        }
      }

      return newRecord;
    });

    dispatch(
      updateUserBonusPlan({ userId, assignments: cleanupFromCurrValuesBody }),
    );
  };

  const renderContent = () =>
    !!historicalAssignments ? (
      <HistoricalDataTable
        historicalAssignments={historicalAssignments}
        handleHistoricalDataChange={handleHistoricalDataChange}
      />
    ) : (
      <NoReportsComponent />
    );

  const content = renderContent();

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth={'lg'}>
      <DialogTitle>
        Historical data edit
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 11,
            color: ({ palette }) => palette.action.active,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="body2">Adding 0 deletes a bonus entry</Typography>
      </DialogTitle>
      <DialogContent sx={{ px: 0, py: 0 }} dividers>
        {content}
      </DialogContent>
      <Stack
        direction="row"
        justifyContent="flex-end"
        spacing={0.25}
        sx={{
          height: 'fit-content',
        }}
      >
        <DialogActions sx={{ px: 4.5 }}>
          <Button onClick={onClose}>Close</Button>
          <Button onClick={() => onSaveBonuses()} variant="contained">
            Save
          </Button>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};

export default HistoricalDataEditModal;
