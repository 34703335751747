import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Stack, Box, Alert, Typography, Paper, Button } from '@mui/material';
import WorkOffRoundedIcon from '@mui/icons-material/WorkOffRounded';
import {
  API_STATUS,
  appConfig,
  KPI_FREQUENCY,
  KPI_SCOPES,
} from '../../constants';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { fetchKpiMetrics, clearKpiMetrics } from '../../redux/kpiMetrics';
import {
  clearContractor,
  contractorSelectors,
  deleteKpiAssignment,
  DeleteKpiAssignmentBodyType,
  fetchContractor,
  AddKpiAssignmentBodyType,
  addKpiAssignment,
  UpdateKpiAssignmentBodyType,
  updateKpiAssignment,
  fetchKpiAllTimeMetrics,
  BonusShareUpsertType,
  getKpiAssignment,
  clearAssignmentToEdit,
  getAllAssignmentsWithHistoricalData,
} from '../../redux/contractor';
import { ButtonMenuGroup, PageWrapper } from '../../components';
import Details from './Details';
import KpiMetricTable from './components/kpiMetricTable/KpiMetricTable';
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';
import { EditableKpiType } from '../../types/kpiAssign';
import {
  getPeriodBySelectedFilter,
  getDefaultPeriodFilter,
  AllTimeFilter,
  getContractorBreadcrumbs,
  getFilterPeriodOptions,
  getLastQuarter,
  getCurrentQuarter,
  addMonthsToPeriod,
} from './Contractor.utils';
import KpiAssignModal from '../kpiAssignModal/KpiAssignModal';
import {
  IndividualKpi,
  SharedKpiModel,
  PeriodType,
  GeneralKpiModel,
} from '../../types';
import { FilterPeriodModel } from './Contractor.types';
import { PeriodDataWidget } from './components/periodDataWidget/PeriodDataWidget';
import { fetchPeriods, periodsSelectors } from '../../redux/periods';
import { ContractorEmptyState } from './components/emptyState/EmptyState';
import { fetchUserBonusPlan, userDataSelectors } from '../../redux/userData';
import { useCalculateDates } from './ContractorsPage.hooks';
import { paths } from '../../constants/paths';
import AddIconRounded from '@mui/icons-material/AddRounded';
import EventNoteRoundedIcon from '@mui/icons-material/EventNoteRounded';
import { AuthService } from '../../services/auth-service';
import { RockyInterceptorService } from '../../services/rocky-integration-interceptor-service';
import {
  BONUSES_READY,
  EVENT_NAME,
  REQUEST_TOKEN_TYPE,
  TOKEN_TYPE,
} from '../../constants/rocky';
import { getRockyHostNames } from '../../utils/getLocalHostname';
import { BonusPlanWidget } from './components/bonusPlanWidget/BonusPlanWidget';
import HistoricalDataEditModal from './components/historicalBonusEditModal/HistoricalDataEditModal';
import * as _ from 'lodash';
import {
  BonusHistory,
  HistoricalAssignmentEntry,
} from '../../types/contractor';
import { v4 } from 'uuid';

const Contractor = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { userId } = useParams();

  const location = useLocation();

  const isRockyBonusesPage = location.pathname.includes(paths.rockyBonuses);

  const contractorData = useAppSelector(contractorSelectors.getContractorData);
  const contractorApiStatus = useAppSelector(
    contractorSelectors.getContractorApiStatus,
  );
  const selfMetrics = location.pathname.includes(paths.myMetrics);
  const bonusHistory: BonusHistory[] =
    useAppSelector(contractorSelectors.getBonusHistory) ?? [];

  const allAssignments = useAppSelector(
    contractorSelectors.getAllAssignmentsWithHistoricalData,
  );

  const currentPeriod: PeriodType | null = useAppSelector(
    periodsSelectors.getCurrentPeriod,
  );

  const historicalAssignments: Record<string, HistoricalAssignmentEntry> =
    useMemo(
      () =>
        _.keyBy(
          allAssignments.map((a) => {
            const modifiedKpiValuesForEdit = a.kpiValues
              .map((val) => {
                const lastQuarter = Array.from(
                  { length: 3 },
                  (_, i) => i + Number(getLastQuarter(val.period)[0]),
                );

                const bonusPlan:
                  | number
                  | { period: number; bonusShare: number }[] =
                  a.kpiDefinition.frequency === KPI_FREQUENCY.QUARTERLY
                    ? lastQuarter
                        .map((p) => ({
                          period: p,
                          bonusShare:
                            a.bonusPlanEntries.find((bp) => bp.period === p)
                              ?.bonusShare ?? 0,
                        }))
                        .sort((a, b) => a.period - b.period)
                    : a.bonusPlanEntries.find((bp) => bp.period === val.period)
                        ?.bonusShare ?? 0;

                return {
                  ...val,
                  ...{
                    [a.kpiDefinition.frequency === KPI_FREQUENCY.QUARTERLY
                      ? 'bonusPlan'
                      : 'bonusShare']: bonusPlan,
                  },
                };
              })
              .sort((a, b) => a.period - b.period);

            const currentQuarter = Array.from(
              { length: 3 },
              (_, i) => i + Number(getCurrentQuarter(currentPeriod?.period)[0]),
            );

            if (
              a.bonusPlanEntries.find(
                (bp) => bp.period === currentPeriod?.period,
              )
            ) {
              const currBonusPlan:
                | number
                | { period: number; bonusShare: number }[] =
                a.kpiDefinition.frequency === KPI_FREQUENCY.QUARTERLY
                  ? currentQuarter
                      .map((p) => ({
                        period: p,
                        bonusShare:
                          a.bonusPlanEntries.find((bp) => bp.period === p)
                            ?.bonusShare ?? 0,
                      }))
                      .sort((a, b) => a.period - b.period)
                  : a.bonusPlanEntries.find(
                      (bp) => bp.period === currentPeriod?.period,
                    )?.bonusShare ?? 0;

              const currentKpiValue = {
                id: v4(),
                period:
                  a.kpiDefinition.frequency === KPI_FREQUENCY.QUARTERLY
                    ? addMonthsToPeriod(currentQuarter[2], 1)
                    : currentPeriod?.period,
                worstCase: a.worstCase,
                bestCase: a.bestCase,
                [a.kpiDefinition.frequency === KPI_FREQUENCY.QUARTERLY
                  ? 'bonusPlan'
                  : 'bonusShare']: currBonusPlan,
              };

              modifiedKpiValuesForEdit.push(currentKpiValue);
            }

            return {
              id: a.id,
              kpiValues: modifiedKpiValuesForEdit,
              name: a.kpiDefinition.name,
              scope: a.kpiDefinition.scope,
              frequency: a.kpiDefinition.frequency,
              type: a.kpiDefinition.type,
              kpiDefinitionId: a.kpiDefinitionId,
            };
          }),
          'id',
        ),
      [allAssignments, currentPeriod],
    );

  const [tokenReceived, setTokenReceived] = useState(false);

  const currentPeriodApiStatus = useSelector(
    periodsSelectors.getPeriodsApiStatus,
  );

  const defaultFilter = useMemo(
    () => getDefaultPeriodFilter(currentPeriod),
    [currentPeriod],
  );

  const [filteredPeriod, setFilteredPeriod] = useState<FilterPeriodModel>();

  const [showKpiAssignModal, setShowKpiAssignModal] = useState<boolean>(false);
  const user = useAppSelector(userDataSelectors.getUserData);

  const deactivatedString = useCalculateDates(contractorData, currentPeriod);

  const isLoading =
    contractorApiStatus === API_STATUS.LOADING ||
    currentPeriodApiStatus === API_STATUS.LOADING;

  const messageHandler = (event: any) => {
    if (!getRockyHostNames().includes(event.origin)) return;

    const { token, type } = event.data;
    if (type === TOKEN_TYPE) {
      AuthService.setRockyToken(token);
      setTokenReceived(true);
    }
  };

  useEffect(() => {
    if (!isLoading && isRockyBonusesPage) {
      window.top?.postMessage(
        {
          type: BONUSES_READY,
          height: document.body.offsetHeight,
        },
        '*',
      );
    }
  }, [isLoading, isRockyBonusesPage]);

  useEffect(() => {
    if (isRockyBonusesPage) {
      window.addEventListener(EVENT_NAME, messageHandler);

      RockyInterceptorService.init(appConfig);

      window.top?.postMessage(
        {
          type: REQUEST_TOKEN_TYPE,
        },
        '*',
      );

      return () => {
        window.removeEventListener(EVENT_NAME, messageHandler);
      };
    }
  }, [isRockyBonusesPage]);

  const [dateFrom, dateTo] = useMemo(
    () =>
      getPeriodBySelectedFilter(
        currentPeriod,
        filteredPeriod ?? defaultFilter,
      ) || [],
    [currentPeriod, filteredPeriod, defaultFilter],
  );

  const fetchData = () => {
    if (isRockyBonusesPage && !tokenReceived) {
      return;
    }

    dispatch(fetchPeriods());

    if (userId || selfMetrics) {
      dispatch(
        fetchContractor({
          userId: userId ?? '',
          dateFrom,
          dateTo,
          selfMetrics,
          byZoho: isRockyBonusesPage,
        }),
      );
    }
  };

  useEffect(() => {
    if (isRockyBonusesPage && !tokenReceived) {
      return;
    }

    if (contractorData.id || userId) {
      dispatch(
        fetchKpiMetrics({
          userId: (selfMetrics ? contractorData.id : userId) ?? '',
          byZoho: isRockyBonusesPage,
        }),
      );
    }
    // eslint-disable-next-line
  }, [contractorData.id, userId, selfMetrics, isRockyBonusesPage]);

  useEffect(() => {
    window.scroll({ top: 0 });
  }, []);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [dateFrom, dateTo, userId, selfMetrics, tokenReceived]);

  useEffect(() => {
    return () => {
      dispatch(clearKpiMetrics());
      dispatch(clearContractor());
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isRockyBonusesPage && contractorData.id) {
      dispatch(fetchUserBonusPlan(contractorData.id));
    }
    // eslint-disable-next-line
  }, [contractorData.id, dispatch]);

  const handleAssignMetric = () => {
    setShowKpiAssignModal(true);
  };

  const handleEditMetric = (kpi: IndividualKpi | SharedKpiModel) => {
    dispatch(getKpiAssignment(kpi.id));
    setShowKpiAssignModal(true);
  };

  const handleAddMetric = async (values: EditableKpiType) => {
    const body: AddKpiAssignmentBodyType = {
      userId: contractorData.id,
      kpiDefinitionId: values.kpiDefinitionId,
      bonusShare:
        (typeof values.bonusShare === 'number' ? values?.bonusShare : 0) ?? 0,
      bestCase: values.bestCase,
      worstCase: values.worstCase,
      startPeriod: values.startPeriod,
      endPeriod: values.endPeriod || null,
    };
    if (values.kpiDefinition.scope === KPI_SCOPES.INDIVIDUAL) {
      body.kpiOwnerId = values.kpiOwnerId;
    }
    await dispatch(addKpiAssignment(body));
    handleCloseMetricModal();
    await fetchData();
  };

  const handleUpdateMetric = async (values: EditableKpiType) => {
    const body: UpdateKpiAssignmentBodyType = {
      userId: contractorData.id,
      id: values.id,
      kpiDefinitionId: values.kpiDefinitionId,
      bonusShare:
        typeof values.bonusShare === 'number'
          ? {
              type: BonusShareUpsertType.EFFECTIVE_FROM,
              value: values.bonusShare,
              effectiveFrom: values.effectiveFrom,
            }
          : values.bonusShare?.map((bp) => ({
              bonusShare: bp.bonusShare ?? 0,
              period: bp.period,
              type: BonusShareUpsertType.PERIOD,
            })),
      bestCase: values.bestCase,
      worstCase: values.worstCase,
      startPeriod: values.startPeriod,
      endPeriod: values.endPeriod || null,
    };
    if (values.kpiDefinition.scope === KPI_SCOPES.INDIVIDUAL) {
      body.kpiOwnerId = values.kpiOwnerId;
    }
    await dispatch(updateKpiAssignment(body));
    handleCloseMetricModal();
    await fetchData();
  };

  const handleCloseMetricModal = async () => {
    setShowKpiAssignModal(false);
    dispatch(clearAssignmentToEdit());
    dispatch(fetchUserBonusPlan(contractorData.id));
  };

  const handleDeleteMetric = async (userKpiAssignmentId: string) => {
    const body: DeleteKpiAssignmentBodyType = {
      userId: contractorData.id,
      userKpiAssignmentId,
    };
    await dispatch(deleteKpiAssignment(body));

    handleCloseMetricModal();

    await fetchData();
  };

  const handleGetAllResults = (kpi: GeneralKpiModel) => {
    if (contractorData.id) {
      const [dateFrom, dateTo] =
        getPeriodBySelectedFilter(currentPeriod, AllTimeFilter) || [];

      dispatch(
        fetchKpiAllTimeMetrics({
          userId: contractorData.id,
          kpiId: kpi.id,
          dateFrom,
          dateTo,
        }),
      );
    }
  };

  const { kpiStats = {} } = contractorData;

  const menuWidth = '208px';
  const renderMetrics = () =>
    isRockyBonusesPage ? (
      <Box sx={{ width: `calc(100% - ${menuWidth})` }}>
        <KpiMetricTable
          sharedKpis={contractorData.sharedKpis}
          individualKpis={contractorData.individualKpis}
          handleEditMetric={handleEditMetric}
          handleDeleteMetric={handleDeleteMetric}
          currentPeriod={currentPeriod}
          hideMoreActions={!contractorData.canModifyMetrics}
          filteredPeriod={filteredPeriod ?? defaultFilter}
          getAllResults={handleGetAllResults}
        />
      </Box>
    ) : (
      <Stack
        sx={{ width: '100%' }}
        direction="row"
        justifyContent="space-between"
      >
        <Box sx={{ width: 'calc(100% - 248px)' }}>
          <KpiMetricTable
            sharedKpis={contractorData.sharedKpis}
            individualKpis={contractorData.individualKpis}
            handleEditMetric={handleEditMetric}
            handleDeleteMetric={handleDeleteMetric}
            currentPeriod={currentPeriod}
            filteredPeriod={filteredPeriod ?? defaultFilter}
            getAllResults={handleGetAllResults}
            hideMoreActions={!contractorData.canModifyMetrics}
          />
        </Box>
        <Box>
          <PeriodDataWidget
            filteredPeriod={filteredPeriod ?? defaultFilter}
            {...kpiStats}
          />
          {bonusHistory && user && (
            <BonusPlanWidget bonusHistory={bonusHistory} />
          )}
        </Box>
      </Stack>
    );

  const renderEmptyMetrics = useCallback(
    () => (
      <Box sx={{ width: 'calc(100%)', height: 'calc(100vh - 228px)' }}>
        <ContractorEmptyState
          selfUser={contractorData.email === user?.email}
          name={contractorData.fullName}
          isAllTime={filteredPeriod?.value === AllTimeFilter.value}
        />
      </Box>
    ),
    [
      filteredPeriod,
      contractorData.fullName,
      contractorData.email,
      user?.email,
    ],
  );

  const haveMetrics =
    contractorData.sharedKpis.length || contractorData.individualKpis.length;

  const content = haveMetrics ? renderMetrics() : renderEmptyMetrics();

  const breadcrumbs = getContractorBreadcrumbs(contractorData.breadcrumbs);

  const filterPeriodOptions = useMemo(
    () => getFilterPeriodOptions(currentPeriod),
    [currentPeriod],
  );
  const filterContent = useMemo(
    () => (
      <Box sx={{ width: menuWidth, mb: 1 }}>
        <ButtonMenuGroup<FilterPeriodModel>
          id="selectedPeriod"
          options={filterPeriodOptions}
          value={filteredPeriod ?? defaultFilter}
          getOptionLabel={(option) => option.label}
          getOptionSubtext={(option) => option.subtext}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          onChange={(_, value) => setFilteredPeriod(value)}
          color="highlight"
          typographyVariant="body1"
          fullWidth
          menuWidth={menuWidth}
          startIcon={<EventNoteRoundedIcon style={{ opacity: '0.6' }} />}
        />
      </Box>
    ),
    [filteredPeriod, defaultFilter, filterPeriodOptions],
  );

  const [showEditHistoricalDataModal, setEditHistoricalDataModal] =
    useState(false);

  const toggleEditHistoricalData = () => {
    if (showEditHistoricalDataModal) fetchData();
    else dispatch(getAllAssignmentsWithHistoricalData(contractorData.id));
    setEditHistoricalDataModal(!showEditHistoricalDataModal);
  };

  return (
    <PageWrapper
      hideOverflowX={isRockyBonusesPage}
      isLoading={isLoading}
      maxWidth={'1500px'}
    >
      {!isRockyBonusesPage && (
        <>
          <Breadcrumbs
            breadcrumbs={breadcrumbs}
            showPageLink={user?.permissions.canViewListOfContractors}
            rootPath={paths.contractors}
            pageName="Teams"
          />
          <Details
            contractorData={contractorData}
            handleAssignMetric={handleAssignMetric}
            filteredPeriod={filteredPeriod ?? defaultFilter}
            setFilteredPeriod={(value) => value && setFilteredPeriod(value)}
            currentPeriod={currentPeriod}
            hideAssignMetric={!contractorData.canModifyMetrics}
            userCanEditHistoricalData={user?.permissions.canEditHistoricalData}
            hideHistoricalEdit={false} // set to false to enable historical edit later
            toggleEditHistoricalData={toggleEditHistoricalData}
          />
        </>
      )}
      {deactivatedString && (
        <Alert
          severity="info"
          variant="standard"
          elevation={0}
          icon={<WorkOffRoundedIcon color="secondary" />}
          sx={{
            marginBottom: 3,
          }}
        >
          <Typography variant="body1">{deactivatedString}</Typography>
        </Alert>
      )}

      <Stack direction="row" justifyContent="space-between">
        {content}

        {isRockyBonusesPage && (
          <Paper
            variant="outlined"
            sx={{
              mx: 0.25,
              p: 2,
              width: 240,
            }}
          >
            {filterContent}

            {contractorData.canModifyMetrics && (
              <Box mt={2}>
                <Button
                  variant="contained"
                  startIcon={<AddIconRounded fontSize="small" />}
                  onClick={handleAssignMetric}
                >
                  Assign metric
                </Button>
              </Box>
            )}
            <Box p={2}>
              <PeriodDataWidget
                filteredPeriod={filteredPeriod ?? defaultFilter}
                hidePaper
                {...kpiStats}
              />
            </Box>
          </Paper>
        )}
      </Stack>

      {showKpiAssignModal && (
        <KpiAssignModal
          handleAddKpiAssignment={handleAddMetric}
          handleCloseModal={handleCloseMetricModal}
          handleDeleteKpiAssignment={handleDeleteMetric}
          handleUpdateKpiAssignment={handleUpdateMetric}
          isModalOpen={showKpiAssignModal}
          userId={contractorData.id}
          showCustomBackdrop={isRockyBonusesPage}
          stickDialogToTop={isRockyBonusesPage}
        />
      )}

      {showEditHistoricalDataModal && (
        <HistoricalDataEditModal
          userId={contractorData.id}
          isOpen={showEditHistoricalDataModal}
          onClose={toggleEditHistoricalData}
          historicalAssignments={historicalAssignments}
        />
      )}
    </PageWrapper>
  );
};

export default Contractor;
