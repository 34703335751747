import React, { useEffect, useState } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import {
  formatYYYYMMDateToMMMMYString,
  cn,
  yyyyMMToQuarterName,
  formatDateToYYYYMM,
} from '../../../../../utils';
import {
  HistoricalAssignmentEntry,
  UpdateHistoricalAssignment,
} from '../../../../../types/contractor';
import { HistoricalDataNumberField } from './HistoricalDataNumberField';
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import AddIconRounded from '@mui/icons-material/AddRounded';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import {
  contractorSelectors,
  getSharedResultsForAssignment,
} from '../../../../../redux/contractor';
import { KPI_FREQUENCY, KPI_SCOPES, KPI_TYPES } from '../../../../../constants';
import { getLastQuarter, subMonthsFromPeriod } from '../../../Contractor.utils';
import PeriodPicker from './PeriodPicker';
import { periodsSelectors } from '../../../../../redux/periods';

interface Props {
  assignmentEntry: HistoricalAssignmentEntry;
  handleHistoricalDataChange: (ae: UpdateHistoricalAssignment) => void;
}

const HistoricalAssignmentDataRow: React.FC<Props> = ({
  assignmentEntry,
  handleHistoricalDataChange,
}) => {
  const dispatch = useAppDispatch();

  const [showPeriodRows, setShowPeriodRows] = useState(false);
  const currentPeriod: number | null =
    useAppSelector(periodsSelectors.getCurrentPeriod)?.period ??
    formatDateToYYYYMM(new Date());
  const isHistoricalDataUpdating = useAppSelector(
    contractorSelectors.isUpdatingHistoricalData,
  );
  const [sharedResults, setSharedResults] = useState(
    {} as Record<string, number>,
  );

  const fetchSharedResults = async () => {
    const { payload } = await dispatch(
      getSharedResultsForAssignment(assignmentEntry.kpiDefinitionId),
    );

    setSharedResults(payload);
  };

  useEffect(() => {
    if (assignmentEntry.scope === KPI_SCOPES.SHARED) fetchSharedResults();
    // eslint-disable-next-line
  }, [assignmentEntry]);

  const handleDataValueChanges = ({
    field,
    period,
    value,
  }: {
    field: string;
    period: number;
    value: number;
  }) => {
    let updatedKpis: any[] = [];
    if (
      field === 'bonusShare' &&
      assignmentEntry.frequency === KPI_FREQUENCY.QUARTERLY
    ) {
      updatedKpis = assignmentEntry.kpiValues.map((kpi: any) => {
        if (kpi.period > period && subMonthsFromPeriod(period, 3) <= period) {
          return {
            ...kpi,
            ...{
              bonusPlan: kpi.bonusPlan.map((bp: any) => {
                if (bp.period === period) {
                  return {
                    ...bp,
                    ...{ bonusShare: value },
                  };
                }
                return bp;
              }),
            },
          };
        }
        return kpi;
      });
    } else {
      updatedKpis = assignmentEntry.kpiValues.map((kpi: any) => {
        if (kpi.period === period) {
          return { ...kpi, ...{ [field]: value } };
        }
        return kpi;
      });
    }

    assignmentEntry = { ...assignmentEntry, ...{ kpiValues: updatedKpis } };

    handleHistoricalDataChange({
      id: assignmentEntry.id,
      [assignmentEntry.frequency === KPI_FREQUENCY.QUARTERLY
        ? 'quarterlyKpiValues'
        : 'monthlyKpiValues']: updatedKpis,
    });
  };

  const renderPeriodRow = (historicalKpiValue: any) => {
    const isCurrent =
      assignmentEntry.frequency === KPI_FREQUENCY.MONTHLY
        ? historicalKpiValue.period === currentPeriod
        : historicalKpiValue.bonusPlan
            .map((bp: any) => bp.period)
            .includes(currentPeriod);

    return (
      <TableRow
        key={`${historicalKpiValue.id}`}
        hover={true}
        className={cn('no-height')}
      >
        <TableCell sx={{ color: 'text.secondary' }}>
          {assignmentEntry.name}
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {assignmentEntry.frequency === KPI_FREQUENCY.MONTHLY
            ? formatYYYYMMDateToMMMMYString(historicalKpiValue.period)
            : yyyyMMToQuarterName(historicalKpiValue.period, true)}
          <br />
          {isCurrent && '(current)'}
        </TableCell>

        {[KPI_TYPES.FIXED_ABSOLUTE, KPI_TYPES.FIXED_PERCENTAGE].includes(
          assignmentEntry.type,
        ) ? (
          <>
            <TableCell
              align={'right'}
              sx={{ whiteSpace: 'nowrap', color: 'text.disabled' }}
            >
              <Typography whiteSpace="nowrap" variant="h3" pl={0.5}>
                &mdash;
              </Typography>
            </TableCell>
            <TableCell
              align={'right'}
              sx={{ whiteSpace: 'nowrap', color: 'text.disabled' }}
            >
              <Typography whiteSpace="nowrap" variant="h3" pl={0.5}>
                &mdash;
              </Typography>
            </TableCell>
          </>
        ) : (
          <>
            <TableCell align={'right'} sx={{ whiteSpace: 'nowrap' }}>
              <HistoricalDataNumberField
                id={'worstCase'}
                name={'worstCase'}
                value={historicalKpiValue.worstCase}
                disabled={isHistoricalDataUpdating || isCurrent}
                handleChange={(e: any) =>
                  handleDataValueChanges({
                    field: 'worstCase',
                    period: historicalKpiValue.period,
                    value: e.value,
                  })
                }
                isPercentage={assignmentEntry.type.includes('PERCENTAGE')}
              />
            </TableCell>

            <TableCell align={'right'} sx={{ whiteSpace: 'nowrap' }}>
              <HistoricalDataNumberField
                id={'bestCase'}
                name={'bestCase'}
                value={historicalKpiValue.bestCase}
                disabled={isHistoricalDataUpdating || isCurrent}
                handleChange={(e: any) =>
                  handleDataValueChanges({
                    field: 'bestCase',
                    period: historicalKpiValue.period,
                    value: e.value,
                  })
                }
                isPercentage={assignmentEntry.type.includes('PERCENTAGE')}
              />
            </TableCell>
          </>
        )}

        {assignmentEntry.scope === KPI_SCOPES.SHARED &&
        !!Object.keys(sharedResults).includes(
          historicalKpiValue.period.toString(),
        ) ? (
          <TableCell align={'right'} sx={{ whiteSpace: 'nowrap', pr: 4 }}>
            {historicalKpiValue.value ||
              sharedResults[historicalKpiValue.period]}
          </TableCell>
        ) : (
          <TableCell align={'right'} sx={{ whiteSpace: 'nowrap' }}>
            <HistoricalDataNumberField
              id={'result'}
              name={'result'}
              value={historicalKpiValue.value}
              disabled={isHistoricalDataUpdating || isCurrent}
              handleChange={(e: any) =>
                handleDataValueChanges({
                  field: 'value',
                  period: historicalKpiValue.period,
                  value: e.value,
                })
              }
              isPercentage={assignmentEntry.type.includes('PERCENTAGE')}
            />
          </TableCell>
        )}

        <TableCell align={'right'} sx={{ pr: 4.5 }}>
          {assignmentEntry.frequency === KPI_FREQUENCY.MONTHLY ? (
            <HistoricalDataNumberField
              id={'bonusShare'}
              name={'bonusShare'}
              formatProps={{ maxLength: 7 }}
              value={historicalKpiValue.bonusShare}
              disabled={isHistoricalDataUpdating || isCurrent}
              handleChange={(e: any) =>
                handleDataValueChanges({
                  field: 'bonusShare',
                  period: historicalKpiValue.period,
                  value: e.value,
                })
              }
            />
          ) : (
            <Stack>
              {historicalKpiValue.bonusPlan.map((bp: any) => (
                <Box
                  display={'flex'}
                  key={`${historicalKpiValue.id}-${bp.period}`}
                  alignItems={'center'}
                  justifyContent={'flex-end'}
                  gap={1}
                  pr={0}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{ color: 'text.disabled' }}
                  >
                    {formatYYYYMMDateToMMMMYString(bp.period, true)}
                  </Typography>
                  <HistoricalDataNumberField
                    id={`${assignmentEntry.id}-bonusShare-${bp.period}`}
                    name={`${assignmentEntry.id}-bonusShare-${bp.period}`}
                    value={bp.bonusShare}
                    disabled={isHistoricalDataUpdating || isCurrent}
                    handleChange={(e: any) =>
                      handleDataValueChanges({
                        field: 'bonusShare',
                        period: bp.period,
                        value: e.value,
                      })
                    }
                  />
                </Box>
              ))}
            </Stack>
          )}
        </TableCell>
      </TableRow>
    );
  };

  const assignmentId: string = assignmentEntry?.id ?? '';

  const calendarAnchor = React.useRef<HTMLElement | null>(null);
  const [isCalendarOpen, setCalendarOpen] = useState(false);
  const toggleCalendarPopup = () => setCalendarOpen(!isCalendarOpen);

  const addKpivalue = (period: number) => {
    if (!assignmentEntry.kpiValues.find((k) => k.period === period)) {
      const isFixedTypeAssignment = [
        KPI_TYPES.FIXED_ABSOLUTE,
        KPI_TYPES.FIXED_PERCENTAGE,
      ].includes(assignmentEntry.type);
      const newKpiValue = {
        period: period,
        value: 0,
        worstCase: isFixedTypeAssignment ? undefined : 0,
        bestCase: isFixedTypeAssignment ? undefined : 0,
      };

      if (assignmentEntry.frequency === KPI_FREQUENCY.QUARTERLY) {
        const lastQuarter = Array.from(
          { length: 3 },
          (_, i) => i + Number(getLastQuarter(period)[0]),
        );

        const newBonusPlan = lastQuarter.map((p) => ({
          period: p,
          bonusShare: 0,
        }));

        assignmentEntry.kpiValues.unshift({
          ...newKpiValue,
          ...{ bonusPlan: newBonusPlan },
          ...{ value: sharedResults[period] ?? 0 },
        });
      } else {
        assignmentEntry.kpiValues.unshift({
          ...newKpiValue,
          ...{ bonusShare: 0 },
          ...{ value: sharedResults[period] ?? 0 },
        });
      }
    }
  };

  return (
    <>
      <TableRow
        key={assignmentId}
        hover={true}
        className={cn('no-height', !!assignmentEntry && 'expandable')}
        onClick={() => {
          fetchSharedResults();
          setShowPeriodRows(!showPeriodRows);
        }}
      >
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {assignmentEntry.name ?? ''}
          <IconButton
            aria-label="Expand/collapse icon"
            size="small"
            sx={{
              transform: 'scale(0.75)',
              opacity: 0.75,
              my: -0.5,
            }}
          >
            {showPeriodRows ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell colSpan={showPeriodRows ? 4 : 5}></TableCell>
        {showPeriodRows && (
          <TableCell align="right" ref={calendarAnchor}>
            <Tooltip title="Add new period" placement="top">
              <IconButton
                aria-label="Add new value"
                size="medium"
                sx={{
                  transform: 'scale(0.75)',
                  opacity: 0.75,
                  my: -0.5,
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  toggleCalendarPopup();
                }}
              >
                <AddIconRounded fontSize="medium" />
              </IconButton>
            </Tooltip>
          </TableCell>
        )}
      </TableRow>

      <PeriodPicker
        anchorEl={calendarAnchor}
        open={isCalendarOpen}
        handlePopperClose={() => setCalendarOpen(false)}
        isQuarterly={assignmentEntry.frequency === KPI_FREQUENCY.QUARTERLY}
        addNewKpi={addKpivalue}
      />

      {showPeriodRows && assignmentEntry.kpiValues.map(renderPeriodRow)}
    </>
  );
};

export default HistoricalAssignmentDataRow;
